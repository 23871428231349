import { keyBy, isArray, isObject } from "lodash";
import { axios } from "../connection/ConnectionHandler";
import { SHOW_NOTIFICATION } from "../constants";
import moment from "moment";

const defaultFormatter = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: item.name,
        value: item._id,
        ...Object.keys(item).reduce((acc, key) => {
            if (item[key] && moment(item[key], moment.ISO_8601, true).isValid()) {
                acc[key] = moment(item[key]);
            }
            return acc;
        }, {})
    });

    if (isArray(data)) {
        return data.map(formatItem);
    }
    if (isObject(data)) {
        return formatItem(data);
    }
};

export const createCrudActions = (entityName, entityEsName, customFormatter = defaultFormatter) => {
    const uppercaseEntity = entityName.toUpperCase();

    return {
        fetch: () => async (dispatch) => {
            try {
                const response = await axios.get(entityName);
                const formattedData = customFormatter(response.data);
                dispatch({
                    type: `SET_${uppercaseEntity}_LIST`,
                    payload: keyBy(formattedData, "_id")
                });
                return response.status;
            } catch (err) {
                console.error(err);
                return err;
            }
        },

        create: (newEntity) => async (dispatch) => {
            try {
                dispatch({ type: `${uppercaseEntity}_FORM_TOGGLE_LOADING` });
                const response = await axios.post(entityName, newEntity);
                const formattedData = customFormatter(response.data);
                dispatch({ type: `ADD_NEW_${uppercaseEntity}`, payload: formattedData });
                dispatch({ type: `${uppercaseEntity}_FORM_TOGGLE_LOADING` });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: `${entityEsName} creado con éxito.`,
                    },
                });
                return response.status;
            } catch (err) {
                dispatch({ type: `${uppercaseEntity}_FORM_TOGGLE_LOADING` });
                return err;
            }
        },

        update: (updatedEntity) => async (dispatch) => {
            try {
                dispatch({ type: `${uppercaseEntity}_FORM_TOGGLE_LOADING` });
                const response = await axios.put(`${entityName}/${updatedEntity._id}`, updatedEntity);
                const formattedData = customFormatter(response.data);
                dispatch({ type: `UPDATE_${uppercaseEntity}`, payload: formattedData });
                dispatch({ type: `${uppercaseEntity}_FORM_TOGGLE_LOADING` });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: `${entityEsName} actualizado.`,
                    },
                });
                return response.status;
            } catch (err) {
                dispatch({ type: `${uppercaseEntity}_FORM_TOGGLE_LOADING` });
                return err;
            }
        },

        delete: (ids) => async (dispatch) => {
            try {
                dispatch({ type: `${uppercaseEntity}_FORM_TOGGLE_LOADING` });
                const response = await axios.delete(entityName, { data: ids });
                dispatch({ type: `DELETE_${uppercaseEntity}`, payload: ids });
                dispatch({ type: `${uppercaseEntity}_FORM_TOGGLE_LOADING` });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: `${entityEsName}(s) eliminado(s).`,
                    },
                });
                return response.status;
            } catch (err) {
                dispatch({ type: `${uppercaseEntity}_FORM_TOGGLE_LOADING` });
                return err;
            }
        },
    };
};

export const createCrudReducers = (entityName) => {
    const uppercaseEntity = entityName.toUpperCase();

    return {
        list: (state = null, action) => {
            switch (action.type) {
                case `SET_${uppercaseEntity}_LIST`:
                    return action.payload;
                case `ADD_NEW_${uppercaseEntity}`:
                case `UPDATE_${uppercaseEntity}`:
                    return { ...state, [action.payload._id]: action.payload };
                case `DELETE_${uppercaseEntity}`:
                    return Object.keys(state)
                        .filter(key => !action.payload.includes(key))
                        .reduce((obj, key) => {
                            obj[key] = state[key];
                            return obj;
                        }, {});
                default:
                    return state;
            }
        },
        form: (state = { show: false, data: null, loading: false }, action) => {
            switch (action.type) {
                case `OPEN_${uppercaseEntity}_FORM`:
                    return { ...state, show: true, data: null };
                case `CLOSE_${uppercaseEntity}_FORM`:
                    return { ...state, show: false, data: null };
                case `EDIT_SELECTED_${uppercaseEntity}`:
                    return { ...state, show: true, data: action.payload };
                case `${uppercaseEntity}_FORM_TOGGLE_LOADING`:
                    return { ...state, loading: !state.loading };
                default:
                    return state;
            }
        },
    };
};