import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  budgets,
  budgetsForm,
  bcUsers,
  products,
  projectsnav,
} from "./Reducers";

export default combineReducers({
  budgets,
  budgetsForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  bcUsers,
  products,
  projectsnav,
});
