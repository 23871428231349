export const API = {
  functionalitys: {
    create: `/functionality`,
    edit: `/functionality/`,
    delete: `/functionality/`,
    list: `/functionality`,
  },
  services: {
    create: `/service`,
    edit: `/service/`,
    delete: `/service/`,
    list: `/service`,
  },
  typeBudgets: {
    create: `/typeBudget`,
    edit: `/typeBudget/`,
    delete: `/typeBudget/`,
    list: `/typeBudget`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
  budgets: {
    uploadImage: `/budget/`,
    createOffer: `/budget/offer/`,
    acceptOffer: `/budget/acceptOffer/`,
    sendInvoice: `/budget/sendInvoice/`
  }
};
